import React from "react";
import "../styles/styles.scss";
import Footer from "./footer";
import CookieBanner from "./cookie";

import Navigation from "./navigation";

const Layout = ({ children }) => {
	return (
		<>
			<Navigation />
			<CookieBanner />
			{children}
			<Footer />
		</>
	);
};

export default Layout;
