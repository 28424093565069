import React from "react";
import Layout from "../../components/layout";
import Lessons from "../../components/lessons";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const SmartPhonePhotographyCourse = () => {
	const data = useStaticQuery(graphql`
		query {
			spLesson1: file(relativePath: { eq: "thumbnails/sp-lesson1.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			spLesson2: file(relativePath: { eq: "thumbnails/sp-lesson2.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			spLesson3: file(relativePath: { eq: "thumbnails/sp-lesson3.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						placeholder: BLURRED
						height: 100
						layout: CONSTRAINED
						quality: 100
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
		}
	`);
	const spLesson1 = data.spLesson1.childImageSharp.gatsbyImageData;
	const spLesson2 = data.spLesson2.childImageSharp.gatsbyImageData;
	const spLesson3 = data.spLesson3.childImageSharp.gatsbyImageData;

	const lessons = [
		{
			heading: "Welcome aboard!",
			paragraphs: [
				"Hi I'm Sarah Bennett and a commercial photographer. I've worked with businesses for the last 8 years as a photographer and since I was 16 in sales and marketing. I'll let you work out how long that is in total, but I'm facing the big 50 in two years!",
				"I've created this course for business owners to learn how to take stunning bespoke images for their social media feeds, because I know that when you're starting out, you don't always have the money to pay a professional photographer. Plus, it's sometimes easier for you to create your day to day social media.",
				"This is a comprehensive course that I teach face to face in my local areas, but I wanted to make it available to a wider audience.",
				"So, grab a coffee, a note pad for the first lesson and I hope you enjoy learning some tips and tricks to enable you to become an expert at creating your images for you business.",
			],

			downloads: null,
		},
		{
			heading: "Lesson 1: Setting up for success",
			paragraphs: [
				"In this lesson we're going to look at the reasons why you can use smart phones for social media but not for your website.",
				"Backgrounds and props.",
				"Light and composition and setting up your smart phone to take images.",
			],

			downloads: null,
		},
		{
			heading: "Lesson 2: Creating a flat lay for social media",
			paragraphs: [
				"Here comes the fun bit!",
				"We're going to create a flat lay and straight on image for our business, using our smart phones. You can watch me first then it's your time after the video to create your own and post into the community group.",
			],
			downloads: [
				{
					title: "Extra Information",
					link: "https://drive.google.com/file/d/10jP3jK7ktlrwXLqIBaX8_liZ9sV8Ysh3/view?usp=sharing",
				},
			],
		},
		{
			heading: "Lesson 3: Editing using Lightroom for mobile",
			paragraphs: [
				"In this final lesson I'll show you how to edit your picture on your phone. Give you names of apps you can download if you don't want to use the internal editing app on your phone.",
				"Look at how to use them in your social media feeds.",
			],

			downloads: null,
		},
	];
	const pages = [
		[
			{
				id: 1,
				videoImg: spLesson3,
				videoLink:
					"https://drive.google.com/file/d/1LM-R1v8H3duIUc8XGz7hZgPVK3D8-6Wa/preview",
				description: "Welcome aboard!",
				lessonIndex: 0,
			},
			{
				id: 2,
				videoImg: spLesson1,
				videoLink:
					"https://drive.google.com/file/d/1zhAg_YbGFbFMKQGm6D4ND-_YFVL0QoqN/preview",
				description: "Lesson 1: Setting up for success",
				lessonIndex: 1,
			},
			{
				id: 3,
				videoImg: spLesson2,
				videoLink:
					"https://drive.google.com/file/d/18Bw3fR4e2lsTwSteZ1hCeNLxp_o_Tm2v/preview",
				description: "Lesson 2: Creating a flat lay for social media",
				lessonIndex: 2,
			},
		],
		[
			{
				id: 4,
				videoImg: spLesson3,
				videoLink:
					"https://drive.google.com/file/d/1HOq-G1ltF2foxT67-zfCDn_hUZZU2jBk/preview",
				description: "Lesson 3: Editing using Lightroom for mobile",
				lessonIndex: 3,
			},
		],
	];
	return (
		<Layout>
			{/* <div>
				<meta name="robots" content="noindex, nofollow" />
				<style
					dangerouslySetInnerHTML={{
						__html: `#__memberspace_modal_protected_page {position:fixed; top:0; left:0; width:100%; height:100%; background:#fff; z-index:2147483646;}`,
					}}
				/>
				<div id="__memberspace_modal_protected_page"> </div>
			</div>
			<GatsbySeo title="Social media photography course" />
			<section className="pt-6">
				<Container>
					<Row className="pb-5">
						<Col>
							<h1 className="text-center">Smart Phone Photography Course</h1>
							<hr className="w-25 mx-auto" />
						</Col>
					</Row>
					<Lessons pages={pages} lessons={lessons} />
				</Container>
			</section> */}
		</Layout>
	);
};

export default SmartPhonePhotographyCourse;
